<template>
  <v-container class="page_container">
    <div v-if="supplierData" class="d-flex align-items-center mt-10 mb-20">
      <h4 class="font-weight-bold">{{ supplierData.name }}</h4>
      <img class="page-main-logo mx-10 object-contain" :src="supplierData.light_logo" alt="">
    </div>
    <div class="row" v-if="supplierData && productData">
      <div class="col-md-7 col-sm-12">
        <GameResultProductCard v-if="['Game', 'Gift card'].includes(page_title)" :product_data="productData"
          :title="page_title" :supplier_data="supplierData" @openInforPopup="handleOpenInforPopup" />
        <CommunicationResultProductCard v-else-if="page_title == 'Mobile Prepaid'" :product_data="productData"
          :title="page_title" :isChosenItem="true" :supplier_data="supplierData" @openInforPopup="handleOpenInforPopup" />
      </div>
      <div class="col-md-1"></div>
      <div class="col-md-4 col-sm-12 offset-sm-0">
        <div v-if="supplierId == weComSupplierID" class="d-flex justify-content-between w-100 mb-5">
          <div class="wecom-btn" @click="activateWeComTab(1)" :class="{ active: activedWeComTabID == 1 }">
            <p class="m-0 font-weight-bold">{{ $t('New Number') }}</p>
            <p class="m-0 fs12">({{ $t('Choose a Number') }})</p>
          </div>
          <div class="wecom-btn font-weight-bold" @click="activateWeComTab(2)"
            :class="{ active: activedWeComTabID == 2 }">
            {{ $t('Port My Number') }}</div>
          <div class="wecom-btn font-weight-bold" @click="activateWeComTab(3)"
            :class="{ active: activedWeComTabID == 3 }">
            {{ $t('Recharge') }}</div>
        </div>
        <v-card elevation="3" class="p-10">
          <div class="font-weight-bold">
            <span class="fs20">{{ $t("Price") }}:</span>
            <span class="fs30">
              {{ user_type_logged_in == "Reseller" ? productData.price : productData.customer_price }}
            </span>
            <span class="fs24">₪</span>
          </div>
          <div class="mt-10">
            <label for="phone_number" class="fs14">
              {{ $t("Phone number") }}
            </label>
            <VuePhoneNumberInput v-model="payment.phone_number" required :border-radius="0" default-country-code="IL"
              :translations="phoneInputTranslations" @update="onUpdatePhoneNumber"
              :disabled="supplierId == weComSupplierID && activedWeComTabID == 1" />
            <div class="red--text fs12 valide-error-msg" v-if="!isPhoneValid || $v.payment.phone_number.$invalid">
              {{ $t('Invalid value') }}
            </div>
            <div class="text-center mt-20">
              <v-btn color="primary" class="text-none" @click="handlePaymentSubmit">
                {{ $t("Purchase") }}
              </v-btn>
            </div>
          </div>
        </v-card>
      </div>
    </div>
    <div class="row" v-if="supplierData && expensiveProduct && expensiveProduct.length">
      <div class="col-md-7">
        <div class="divide_line my-10"></div>
        <h5 class="font-weight-bold mb-10">
          {{ $t('Upgrade to') }}
          <router-link
            :to="{ name: product_router, params: { id: expensiveProduct[0].id, supplier_id: expensiveProduct[0].supplier_id } }">
            <v-btn class="mx-2" fab dark small color="primary">
              <v-icon dark>
                mdi-arrow-up-bold
              </v-icon>
            </v-btn>
          </router-link>

        </h5>
        <GameResultProductCard v-if="['Game', 'Gift card'].includes(page_title)" :product_data="expensiveProduct[0]"
          :title="page_title" :supplier_data="supplierData" @openInforPopup="handleOpenInforPopup" />
        <CommunicationResultProductCard v-else-if="page_title == 'Mobile Prepaid'" :product_data="expensiveProduct[0]"
          :title="page_title" :isChosenItem="false" :supplier_data="supplierData"
          @openInforPopup="handleOpenInforPopup" />
      </div>
    </div>

    <!-- Will redesign this part later -->
    <!-- <section class="product__info" v-if="productDetailsText || upscaleProductData">
      <p v-if="productDetailsText" v-html="productDetailsText" class="text product__text"></p>
      <div v-if="upscaleProductData"
        :style="{ 'background-color': supplierData ? supplierData.product_card_background_color : 'initial' }">
        <div class="product__more-wrapper">
          <div class="product__refresh">
            <span class="product__span">{{ upscaleProductData.name }}</span>
            <span class="product__span">{{ ' ' + upscaleProductData.price + ' ' }}</span>
            <span class="product__span">₪</span>
          </div>
          <div class="product__new-price">
            <span class="product__span">{{ upscaleProductData.name }}</span>
            <span class="product__span">{{ ' ' + upscaleProductData.price + ' ' }}</span>
            <span class="product__span">₪</span>
          </div>
          <router-link :to="{ name: product_router, params: { id: upscaleProductData.id } }" tag="a"
            class="link product__details game-product__details" exact>{{ $t("More details") }}</router-link>
        </div>
        <div v-if="supplierData" class="product__more-img">
          <img class="product__more-cover" :src="supplierData.image" alt="">
        </div>
      </div>
    </section> -->

    <section v-if="supplierData && expensiveProduct && expensiveProduct.length" class="mt-15">
      <h5 class="font-weight-bold">{{ $t("Similar Products") }}</h5>
      <div class="row">
        <div v-for="(product_item) in expensiveProduct" :key="'si_' + product_item.id" class="col-sm-6 col-md-4 col-lg-3">
          <GameProductCard v-if="['Game', 'Gift card'].includes(page_title)" :product_data="product_item"
            :product_router="product_router" :supplier_data="supplierData" :title="$t(page_title)" class="m-5"
            @openInforPopup="handleOpenInforPopup" @changeFavorite="handleChangeFavorite" />
          <CommunicationProductCard v-else-if="page_title == 'Mobile Prepaid'" :product_data="product_item"
            :product_router="product_router" :supplier_data="supplierData" :title="page_title" class="m-5"
            @openInforPopup="handleOpenInforPopup" @changeFavorite="handleChangeFavorite"
            :isSupplierCategoryItem="true" />
        </div>
      </div>
    </section>

    <section id="payment-verification-code-modal" class="modal-default"
      :class="{ 'modal-default_active': paymentVerificationCodeModal.active }">
      <div class="modal-default__container">
        <button class="button modal-default__close" @click="cancelTransactionHandler">
          <img src="/assets/img/close.svg" alt="">
        </button>
        <h5 class="font-weight-bold">
          {{ $t("Purchase verification") }}
        </h5>
        <!-- <p class="text modal-default__text">
          {{ $t("To complete the order, please pass the one-time verification code to the customer, so that they enter it at the dedicated link received via their mobile SMS") }}
        </p> -->
        <v-checkbox v-model="isConfirmPhone" class="checkbox-label-wrap"
          :label="$t('I confirm that the mobile number is correct. And that the {Game/Giftcard} can not be canceled.')"></v-checkbox>
        <!-- <div v-if="paymentVerificationCodeModal.verification_code" class="mt-10 text modal-default__text">
          <div>{{ $t("One-time verification code") }}</div>
          <div class="verification-code">{{ paymentVerificationCodeModal.verification_code }}</div>
        </div> -->
        <div class="d-flex justify-content-center mt-10">
          <v-btn class="text-none mx-5" @click="cancelTransactionHandler">
            {{ $t('Cancel reservation') }}
          </v-btn>
          <v-btn color="primary" :disabled="!isConfirmPhone" class="text-none mx-5" @click="approveTransactionHandler">
            {{ $t('Approval') }}
          </v-btn>
        </div>
      </div>
    </section>

    <ModalWecomActivation ref="modalWecomActivationRef"></ModalWecomActivation>
    <ModalWecomNumbers ref="modalWecomNumbersRef" @selectNewNumber="handleSelectNewNumber"></ModalWecomNumbers>
    <InforPopup :inforData="inforData" :modal_key="modal_key"></InforPopup>
    <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Swal from 'sweetalert2';
import appConfig from '@/appConfig';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, sameAs } from 'vuelidate/lib/validators';

import CategoryShare from '@/components/communication/CategoryShare.vue';
import InforPopup from '@/components/communication/InforPopup.vue';
import GameResultProductCard from '@/components/endUser/game/GameResultProductCard.vue'
import GameProductCard from '@/components/endUser/game/GameProductCard.vue'
import CommunicationProductCard from '@/components/endUser/communication/CommunicationProductCard.vue'
import CommunicationResultProductCard from '@/components/endUser/communication/CommunicationResultProductCard.vue'
import ModalWecomActivation from "@/components/endUser/suppliersPages/ModalWecomActivation.vue";
import ModalWecomNumbers from "@/components/endUser/suppliersPages/ModalWecomNumbers.vue";

export default {
  name: 'SupplierProductPage',
  components: {
    Loading,
    CategoryShare,
    InforPopup,
    GameResultProductCard,
    GameProductCard,
    CommunicationProductCard,
    CommunicationResultProductCard,
    ModalWecomActivation,
    ModalWecomNumbers
  },
  mixins: [validationMixin],
  props: {
    product_router: {
      type: String,
      required: true
    },
    page_title: {
      type: String,
      required: true
    },
    user_type: {
      type: String,
      required: false,
      default: "user"
    },
  },
  data: () => {
    return {
      weComSupplierID: 1100,
      payment: {
        phone_number: '',
        confirmed: false
      },
      paymentVerificationCodeModal: {
        active: false,
        verification_code: null,
        transaction_id: null
      },
      // loading: false,
      modal_key: +new Date(),
      inforData: "",
      isPhoneValid: true,
      countryCallingCode: '+972',
      isConfirmPhone: false,
      activedWeComTabID: '',
      newNumber: '',
      transaction_id: ''
    }
  },
  validations: {
    payment: {
      phone_number: {
        required,
        minLength: minLength(10),
      },
    }
  },
  computed: {
    ...mapState({
      supplier_items: state => state.supplier.supplier_items,
      suppliers: state => state.supplier.suppliers,
      suppliers_products: state => state.supplier.suppliers_products,
      products: state => state.product.products,
      gameCardPaymentRequestInProgress: state => state.game_card_payment.gameCardPaymentRequestInProgress,
      verificationRequestInProgress: state => state.game_card_payment.verificationRequestInProgress,
      paymentRequestInProgress: state => state.payment.paymentRequestInProgress,
      weComNumbers: state => state.payment.weComNumbers
    }),
    productId() {
      return this.$route.params.id
    },
    supplierId() {
      return this.$route.params.supplier_id;
    },
    upscaleProductId() {
      return this.productData ? this.productData.upscale_product_id : null
    },
    upscaleProductSupplierId() {
      return this.upscaleProductData ? this.upscaleProductData.supplier_id : null
    },
    productData() {
      let productDataFromSuppliersData = null;

      if (this.suppliers_products) {
        for (let supplier_id in this.suppliers_products) {
          for (let supplier_product_type in this.suppliers_products[supplier_id]) {
            for (let supplier_product of this.suppliers_products[supplier_id][supplier_product_type]) {
              if (supplier_product.hasOwnProperty('id')
                && supplier_product.id == this.productId) {
                productDataFromSuppliersData = { ...supplier_product }
                break;
              }
            }
          }
        }
      }

      let productDataFromProductsData = null;
      if (this.products && this.productId in this.products) {
        productDataFromProductsData = { ...this.products[this.productId] }
      }

      let productData = productDataFromProductsData ? productDataFromProductsData : productDataFromSuppliersData
      return productData ? productData : null
    },
    productDetailsText() {
      if (!this.productData || !this.supplierData || this.supplierData.type != 1) {
        return ''
      }

      let productDetails = ''
      let productDetailsRowTemplate = '<p>[row_text]</p>'
      if (this.productData.hasOwnProperty('call_minute') && this.productData.call_minute != '' && this.productData.call_minute != null) {
        productDetails += productDetailsRowTemplate.replace('[row_text]', 'דקות שיחה:' + ' ' + this.productData.call_minute)
      }
      if (this.productData.hasOwnProperty('sms') && this.productData.sms != '' && this.productData.sms != null) {
        productDetails += productDetailsRowTemplate.replace('[row_text]', 'הודעות:' + ' ' + this.productData.sms)
      }
      if (this.productData.hasOwnProperty('browsing_package') && this.productData.browsing_package != '' && this.productData.browsing_package != null) {
        productDetails += productDetailsRowTemplate.replace('[row_text]', 'נפח גלישה:' + ' ' + this.productData.browsing_package)
      }
      if (this.productData.hasOwnProperty('usage_time_limit') && this.productData.usage_time_limit != '' && this.productData.usage_time_limit != null) {
        productDetails += productDetailsRowTemplate.replace('[row_text]', 'תוקף בימים:' + ' ' + this.productData.usage_time_limit)
      }
      if (this.productData.hasOwnProperty('call_terms') && this.productData.call_terms != '' && this.productData.call_terms != null) {
        productDetails += productDetailsRowTemplate.replace('[row_text]', 'תנאי שיחות:' + ' ' + this.productData.call_terms)
      }
      if (this.productData.hasOwnProperty('call_to_palestinian') && this.productData.call_to_palestinian != false && this.productData.call_to_palestinian != null) {
        productDetails += productDetailsRowTemplate.replace('[row_text]', 'שיחות לרשות:' + ' ' + '✓')
      }
      if (this.productData.hasOwnProperty('abroed_price') && this.productData.abroed_price != '' && this.productData.abroed_price != null) {
        productDetails += productDetailsRowTemplate.replace('[row_text]', 'מחיר לשימוש בחו\'\'ל ובארץ:' + ' ' + this.productData.abroed_price)
      }
      if (this.productData.hasOwnProperty('other1') && this.productData.other1 != '' && this.productData.other1 != null) {
        productDetails += productDetailsRowTemplate.replace('[row_text]', 'אחר 1:' + ' ' + this.productData.other1)
      }
      if (this.productData.hasOwnProperty('other2') && this.productData.other2 != '' && this.productData.other2 != null) {
        productDetails += productDetailsRowTemplate.replace('[row_text]', 'אחר 2:' + ' ' + this.productData.other2)
      }

      return productDetails
    },
    supplierData() {
      let supplierDataFromSuppliersItemsData = null;
      if (this.supplier_items) {
        for (let supplier_type in this.supplier_items) {
          for (let suppliers_type_item of this.supplier_items[supplier_type]) {
            if (suppliers_type_item.id == this.supplierId) {
              supplierDataFromSuppliersItemsData = { ...suppliers_type_item };
              break;
            }
          }
        }
      }

      let supplierDataFromSuppliersData = null;
      if (this.suppliers && this.supplierId in this.suppliers) {
        supplierDataFromSuppliersData = { ...this.suppliers[this.supplierId] }
      }

      let supplierData = supplierDataFromSuppliersData ? supplierDataFromSuppliersData : supplierDataFromSuppliersItemsData
      return supplierData ? supplierData : null
    },
    upscaleProductData() {
      let upscaleProductDataFromSuppliersData = null;

      if (this.suppliers_products) {
        for (let supplier_id in this.suppliers_products) {
          for (let supplier_product_type in this.suppliers_products[supplier_id]) {
            for (let supplier_product of this.suppliers_products[supplier_id][supplier_product_type]) {
              if (supplier_product.hasOwnProperty('id')
                && supplier_product.id == this.upscaleProductId) {
                upscaleProductDataFromSuppliersData = { ...supplier_product }
                break;
              }
            }
          }
        }
      }

      let upscaleProductDataFromProductsData = null;
      if (this.products && this.upscaleProductId in this.products) {
        upscaleProductDataFromProductsData = { ...this.products[this.upscaleProductId] }
      }

      let upscaleProductData = upscaleProductDataFromProductsData ? upscaleProductDataFromProductsData : upscaleProductDataFromSuppliersData
      return upscaleProductData ? upscaleProductData : null
    },
    upscaleProductSupplierData() {
      let upscaleProductSupplierDataFromSuppliersItemsData = null;
      if (this.supplier_items) {
        for (let supplier_type in this.supplier_items) {
          for (let suppliers_type_item of this.supplier_items[supplier_type]) {
            if (suppliers_type_item.id == this.upscaleProductSupplierId) {
              upscaleProductSupplierDataFromSuppliersItemsData = { ...suppliers_type_item };
              break;
            }
          }
        }
      }

      let upscaleProductSupplierDataFromSuppliersData = null;
      if (this.suppliers && this.upscaleProductSupplierId in this.suppliers) {
        upscaleProductSupplierDataFromSuppliersData = { ...this.suppliers[this.upscaleProductSupplierId] }
      }

      let upscaleProductSupplierData = upscaleProductSupplierDataFromSuppliersData ? upscaleProductSupplierDataFromSuppliersData : upscaleProductSupplierDataFromSuppliersItemsData
      return upscaleProductSupplierData ? upscaleProductSupplierData : null
    },
    supplierProductsAll() {
      let supplierProducts = [];
      if (
        this.suppliers_products &&
        this.supplierId in this.suppliers_products
      ) {
        let supplerProductsByType = this.suppliers_products[this.supplierId];

        if (supplerProductsByType) {
          for (let supplier_product_type in supplerProductsByType) {
            for (let supplier_product of supplerProductsByType[
              supplier_product_type
            ]) {
              supplierProducts.push(supplier_product);
            }
          }
        }
      }
      supplierProducts.sort((a, b) => (a.price > b.price) ? 1 : ((b.price > a.price) ? -1 : 0));
      return supplierProducts ? supplierProducts : [];
    },
    expensiveProduct() {
      if (this.productData && this.supplierProductsAll && this.supplierProductsAll.length) {
        let expensiveArray = [];
        for (let product of this.supplierProductsAll) {
          if (product && product.price > this.productData.price) {
            expensiveArray.push(product);
            if (expensiveArray.length == 4) {
              break;
            }
          }
        }
        return expensiveArray;
      }
    },
    loading() {
      return this.gameCardPaymentRequestInProgress || this.paymentRequestInProgress || this.verificationRequestInProgress;
    }
  },
  async created() {
    this.getProduct({ product_id: this.productId });
    this.getSupplier({ supplier_id: this.supplierId });

    if (this.productData && this.productData.upscale_product_id) {
      this.getProduct({ 'product_id': this.productData.upscale_product_id })
    }

    if (this.upscaleProductData && this.upscaleProductData.supplier_id && this.supplierId
      && this.upscaleProductData.supplier_id != this.supplierId) {
      this.getSupplier({ 'supplier_id': this.upscaleProductData.supplier_id })
    }
    if (!this.suppliers_products || this.suppliers_products.length == 0) {
      await this.getSupplierProducts({ supplier_id: this.supplierId });
    }
  },
  watch: {
    upscaleProductId(upscaleProductId) {
      if (!upscaleProductId) {
        return
      }
      this.getProduct({ 'product_id': upscaleProductId })
    },
    upscaleProductSupplierId(upscaleProductSupplierId) {
      if (!upscaleProductSupplierId) {
        return
      }
      this.getSupplier({ 'supplier_id': upscaleProductSupplierId })
    },
    // gameCardPaymentRequestInProgress(inProgress) {
    //   this.loading = inProgress;
    // }
  },
  methods: {
    ...mapActions('product', {
      getProduct: 'getProduct'
    }),
    ...mapActions('supplier', {
      getSupplier: 'getSupplier',
      getSupplierProducts: 'getSupplierProducts',
      setFavoriteProduct: 'setFavoriteProduct'
    }),
    ...mapActions('game_card_payment', {
      gameCardPaymentRequest: 'gameCardPaymentRequest',
      cancelTransactionRequest: 'cancelTransactionRequest',
      paymentVerificationRequest: 'paymentVerificationRequest'
    }),
    ...mapActions('payment', {
      paymentProduct: 'paymentProduct',
      getWeComNumbers: 'getWeComNumbers'
    }),
    ...mapActions("alert", {
      errorAlert: "error"
    }),
    onUpdatePhoneNumber(payload) {
      this.isPhoneValid = payload.isValid;
      if (payload.isValid) {
        this.countryCallingCode = '+' + payload.countryCallingCode
      }
    },
    getApiURL() {
      return appConfig.apiUrl
    },

    handlePaymentSubmit() {
      let obj = this;
      obj.$v.$touch();
      if (obj.$v.$invalid || !obj.isPhoneValid) {
        return;
      }

      Swal.fire({
        title: this.$t("Mobile phone confirmation"),
        text: this.countryCallingCode + ' ' + this.payment.phone_number,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: this.$t("Confirm"),
        cancelButtonText: this.$t("Cancel"),
        preConfirm: () => {
          const phone_number = obj.payment.phone_number.replace(/-/g, "");
          const contract_number = obj.payment.phone_number.replace(/-/g, "");
          const supplier_id = obj.supplierId;
          const product_id = obj.productId;
          const user_type = obj.user_type;
          if (supplier_id && product_id && phone_number) {
            if (['Game', 'Gift card'].includes(obj.page_title)) {
              obj.gameCardPaymentRequest({
                supplier_id: supplier_id,
                payment_data: {
                  itemId: product_id,
                  phoneNumber: phone_number
                }
              })
                .then(
                  result_data => {
                    if (result_data && result_data.hasOwnProperty('currentTransaction') && result_data.currentTransaction
                      && result_data.hasOwnProperty('success') && result_data.success) {
                      let success_data = result_data.success
                      if ('verificationCode' in success_data) {
                        obj.openPaymentVerificationCodeModal({
                          verification_code: success_data.verificationCode,
                          transaction_id: result_data.currentTransaction
                        })
                      }
                    }

                  },
                  error => {
                  }
                );
            } else {
              let isPorted = false;
              if (obj.activedWeComTabID == 2) {
                isPorted = true;
              }

              if (this.weComNumbers.result && this.weComNumbers.result[0].isDBNumber) {
                this.openModalWecomActivation();
              } else {
                obj.paymentProduct({
                  provider_type: this.supplierData?.provider_type || '-',
                  supplier_id,
                  product_id,
                  contract_number,
                  phone_number,
                  user_type,
                  isPorted
                }).then(res => {
                  if (obj.supplierId == 1100 && res.hasOwnProperty('success') && res.success) {
                    this.transaction_id = res.currentTransaction;
                    this.openModalWecomActivation();
                  }
                })
              }

            }
          }
        }
      })
    },
    openPaymentVerificationCodeModal(modal_data) {
      this.paymentVerificationCodeModal.verification_code = modal_data.verification_code
      this.paymentVerificationCodeModal.transaction_id = modal_data.transaction_id
      this.paymentVerificationCodeModal.active = true
      $('html').css("overflow", "hidden");
    },
    closePaymentCodeModal() {
      this.paymentVerificationCodeModal.verification_code = null
      this.paymentVerificationCodeModal.transaction_id = null
      this.paymentVerificationCodeModal.active = false
      $('html').css("overflow", "auto");
    },
    cancelTransactionHandler() {
      let currentPaymentVerificationCodeModalData = { ...this.paymentVerificationCodeModal }

      this.closePaymentCodeModal()

      if (currentPaymentVerificationCodeModalData.transaction_id) {
        this.cancelTransactionRequest({ 'transaction_id': currentPaymentVerificationCodeModalData.transaction_id })
      }
    },
    approveTransactionHandler() {
      let currentPaymentVerificationCodeModalData = { ...this.paymentVerificationCodeModal }

      const verification_code = currentPaymentVerificationCodeModalData.verification_code
      const transaction_id = currentPaymentVerificationCodeModalData.transaction_id
      const phone_number = this.payment.phone_number.replace(/-/g, "");
      const userType = this.user_type;

      if (verification_code && transaction_id && phone_number) {
        this.paymentVerificationRequest({
          supplier_id: null,
          payment_data: {
            transaction_id: transaction_id,
            verification_code: verification_code,
            phoneNumber: phone_number,
            userType: userType
          }
        })
          .then(
            result_data => {
              if (result_data.hasOwnProperty('data') && result_data.data
                && result_data.data.hasOwnProperty('error')
                && result_data.data.error.is_invalid_verification_code) {
                this.errorAlert(this.$t("Incorrect code"));
              } else {
                if (result_data && result_data.hasOwnProperty('currentTransaction') && result_data.currentTransaction
                  && result_data.hasOwnProperty('success') && result_data.success
                  || result_data.hasOwnProperty('status') && result_data.status == 403) {
                  this.redirectToPaymentResult(currentPaymentVerificationCodeModalData.transaction_id)
                  // this.redirectToPaymentResultPublic();
                } else {
                  this.errorAlert(this.$t("Something went wrong, please contact support or try again later"));
                }
              }
            },
            error => {
            }
          );
      }

      this.closePaymentCodeModal();

      // if (currentPaymentVerificationCodeModalData.transaction_id) {
      //   this.redirectToPaymentResult(currentPaymentVerificationCodeModalData.transaction_id)
      // }
    },
    redirectToPaymentResultPublic() {
      this.$router.push({ name: 'PaymentResultPublic', params: { 'transaction_id': this.paymentVerificationCodeModal.transaction_id, 'user_type': this.user_type } })
    },
    redirectToPaymentResult(transaction_id) {
      if (this.user_type == "user") {
        this.$router.push({ name: 'GamePaymentResult', params: { 'transaction_id': transaction_id } });
      } else {
        this.$router.push({ name: 'EndUserGamePaymentResult', params: { 'transaction_id': transaction_id } });
      }
    },
    cancelPaymentHandler() {
      this.payment.confirmed = false;
      this.payment.phone_number = '';
    },
    approvePaymentHandler() {
      this.payment.confirmed = true;
      this.handlePaymentSubmit();
    },
    goUpgradeProduct() {
      this.$router.push({
        name: 'EndUserGameProduct',
        params: {
          id: this.expensiveProduct[0].id,
          supplier_id: this.expensiveProduct[0].supplier_id
        }
      })
    },
    handleChangeFavorite({ id, value }) {
      this.setFavoriteProduct({ product_id: id, supplier_id: this.supplierId, favorite: value });
    },
    /* <!-- ------------information popup-------------- --> */
    handleOpenInforPopup(item) {
      if (item.additional_details == "" || !item.additional_details) {
        this.inforData = item.additional_details_default;
      } else {
        this.inforData = item.additional_details;
      }
      this.$modal.show("inforPopup_" + this.modal_key);
    },

    // -------------------WeCom methods---------------------
    openModalWecomActivation() {
      this.$refs.modalWecomActivationRef.openModal(
        this.payment.phone_number,
        this.supplierId,
        this.transaction_id,
        this.user_type,
        this.activedWeComTabID,
        this.productId
      );
    },
    openModalWecomNumbers() {
      this.$refs.modalWecomNumbersRef.openModal();
    },
    async activateWeComTab(tabId) {
      this.activedWeComTabID = tabId;
      // this.openModalWecomActivation();
      this.payment.phone_number = '';
      if (tabId == 1) {
        // this.openModalWecomActivation();
        if (this.weComNumbers.result && this.weComNumbers.result.length) {
          this.openModalWecomNumbers();
        } else {
          await this.getWeComNumbers();
          this.openModalWecomNumbers();
        }
      }
    },
    handleSelectNewNumber(num) {
      this.newNumber = num;
      this.payment.phone_number = num;
    }
  },
}
</script>

<style>
.checkbox-label-wrap label {
  white-space: normal !important;
}

.checkbox-label-wrap .v-input__slot {
  align-items: initial;
}

#swal2-html-container {
  direction: ltr;
}
</style>

<style lang="scss" scoped>
.wecom-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  border-radius: 5px;
  text-align: center;
  width: calc(100%/3 - 5px);
  background-color: #f5f5f5;
  padding: 5px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  transition: .28s;

  &:hover {
    background-color: #E4E4E4;
  }

  &.active {
    background-color: #29abe2;
    color: #fff;
  }
}
</style>