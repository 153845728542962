<template>
  <main class="page_bg_grey blank-aside-js min-h-100vh w-100" :class = "(isOpenAside=='true')?'blank-aside':''">
    <SupplierProductPage product_router="GiftCardProduct" page_title="Gift card" user_type="user"></SupplierProductPage>
  </main>
</template>

<script>
import SupplierProductPage from '@/components/endUser/suppliersPages/SupplierProductPage.vue';

export default {
  name: 'GiftCardProduct',
  components: {
    SupplierProductPage
  },
}
</script>