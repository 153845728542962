<template>
  <v-card elevation="3">
    <div class="pt-20"
      :style="{ 'background-color': supplier_data ? supplier_data.chosen_card_background_color : 'initial' }">
      <div>
        <p v-if="!product_data.add_vat" class="no-vat-remark">
          {{ $t('No VAT') }}
        </p>
        <CategoryShare></CategoryShare>
        <img src="/assets/img/icons/info-white.svg" alt="" class="infor-icon pointer" @click="handleOpenInforPopup(product_data)"
          v-if="product_data.show_additional_details">
      </div>

      <div style="height: 90px;" class="px-5">
        <p class="font-weight-bold fs20 line-height1" 
          :style="{ 'color': supplier_data ? supplier_data.font_color : 'initial' }"
        >
          {{ product_data.name }}
        </p>
        <div class="text-truncate mb-5" v-html="product_data.description"></div>
      </div>
      <div class="mb-5 white--text text-right px-5">
        <span class="fs30 font-weight-bold">
          {{ user_type_logged_in=="Reseller" ? product_data.price : product_data.customer_price }}
        </span>
        <span class="fs16 font-weight-bold">₪</span>
      </div>
      <div class="d-flex justify-content-between align-items-center py-2 px-5" 
        :style="{ 'background-color': supplier_data ? supplier_data.logo_background_color : 'initial', 'height': '40px' }">
        <div :style="{ 'color': supplier_data ? supplier_data.font_color : 'initial' }">
          {{ title }}
        </div>
        <div v-if="supplier_data" class="h-100">
          <img class="h-100 object-contain" :src="product_data.image || supplier_data.image" alt="">
        </div>
      </div>
      <p class="white--text px-5 py-2 fs14">{{ $t('Valid on days') }}: {{ product_data.usage_time_limit || '---' }}</p>
    </div>
  </v-card>
</template>

<script>
import CategoryShare from '@/components/communication/CategoryShare.vue'

export default {
  name: "GameResultProductCard",
  components: {
    CategoryShare,
  },
  props: {
    product_data: Object,
    supplier_data: Object,
    title: String,
  },
  methods: {
    handleChangeFavorite(id, value) {
      this.$emit('changeFavorite', {id, value});
    },
    handleOpenInforPopup(item) {
      this.$emit('openInforPopup', item);
    }, 
  }
}
</script>

<style lang="scss" scoped>
.infor-icon {
  position: absolute;
  left: 10px;
  top: 10px;
}
</style>