<template>
  <v-card elevation="3" class="d-flex flex-column">
    <div class="pt-20"
    :style="{ backgroundColor: isSupplierCategoryItem ? supplier_data.product_card_background_color : supplier_data.chosen_card_background_color }">
      <div>
        <p v-if="!product_data.add_vat" class="no-vat-remark">
          {{ $t('No VAT') }}
        </p>
        <CategoryShare></CategoryShare>
        <div class="category__star" @click="handleChangeFavorite(product_data.id, !product_data.favorite)">
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25">
            <path :style="{ fill: product_data.favorite ? supplier_data.favorite_star_color : '#fff' }"
              d="M32.158.857,28.97,7.321l-7.133,1.04a1.563,1.563,0,0,0-.864,2.666l5.161,5.029-1.221,7.1A1.561,1.561,0,0,0,27.178,24.8l6.381-3.354L39.941,24.8a1.563,1.563,0,0,0,2.265-1.645l-1.221-7.1,5.16-5.029a1.563,1.563,0,0,0-.864-2.666l-7.133-1.04L34.961.857a1.564,1.564,0,0,0-2.8,0Z"
              transform="translate(-20.5 0.013)" />
          </svg>
        </div>
        <img src="/assets/img/icons/info-white.svg" alt="" class="infor-icon" @click="handleOpenInforPopup(product_data)"
          v-if="product_data.show_additional_details">
      </div>

      <div style="height: 90px;" class="pt-5">
        <p class="font-weight-bold fs18 px-5 line-height1" 
          :style="{ color: isSupplierCategoryItem ? supplier_data.product_font_color : supplier_data.chosen_product_font_color }"
        >
          {{ product_data.name }}
        </p>
      </div>
      <div class="d-flex justify-content-between align-items-center py-2 px-5" 
        :style="{ 'background-color': supplier_data ? supplier_data.product_side_logo_background_color : 'initial', 'height': '40px' }">
        <div :style="{ 'color': supplier_data ? supplier_data.font_color : 'initial' }">
          {{ title }}
        </div>
        <div v-if="supplier_data" class="h-100">
          <img class="h-100 object-contain" :src="supplier_data.image" alt="">
        </div>
      </div>
      <p class="white--text px-5 py-2 fs14">{{ $t('Valid on days') }}: {{ product_data.usage_time_limit || '---' }}</p>
    </div>

    <div class="white text-center p-5 fs14">
      <div style="height: 170px" class="mb-5">
        <div v-html="product_data.description"></div>
        <div class="text-start">
          <p class="mb-0" v-if="product_data.call_minute">
            <span class="font-weight-bold">{{ $t('Minutes of conversation') }}:</span>
            {{ product_data.call_minute }}
          </p>
          <p class="mb-0" v-if="product_data.sms">
            <span class="font-weight-bold">{{ $t('Messages') }}:</span> {{ product_data.sms }}
          </p>
          <p class="mb-0" v-if="product_data.abroed_price">
            <span class="font-weight-bold">{{ $t('Price use abroad Israel') }}:</span>
            {{ product_data.abroed_price }}
          </p>
          <p class="mb-0" v-if="product_data.other1">
            <span class="font-weight-bold">{{ $t('Other 1') }}:</span> {{ product_data.other1 }}
          </p>
          <p class="mb-0" v-if="product_data.browsing_package">
            <span class="font-weight-bold">{{ $t('Surfing') }}:</span>
            {{ product_data.browsing_package }}
          </p>
          <p class="mb-0" v-if="product_data.other2">
            <span class="font-weight-bold">{{ $t('Other 2') }}:</span> {{ product_data.other2 }}
          </p>
        </div>
      </div>
      
      <div class="mb-5">
        <span>{{ $t("Price") }}:</span>
        <span class="fs20 font-weight-bold">
          {{ user_type_logged_in=="Reseller" ? product_data.price : product_data.customer_price }}
        </span>
        <span class="fs16 font-weight-bold">₪</span>
      </div>
      <router-link
        :to="{ name: product_router, params: { id: product_data.id, supplier_id: product_data.supplier_id } }"
      >
        <v-btn color="primary" class="text-none">
          {{ $t("Purchase")  }}
        </v-btn>
      </router-link>
    </div>
  </v-card>
</template>

<script>
import CategoryShare from '@/components/communication/CategoryShare.vue'

export default {
  name: "CommunicationProductCard",
  components: {
    CategoryShare,
  },
  props: {
    product_data: Object,
    supplier_data: Object,
    title: String,
    product_router: String,
    isSupplierCategoryItem: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleChangeFavorite(id, value) {
      this.$emit('changeFavorite', {id, value});
    },
    handleOpenInforPopup(item) {
      this.$emit('openInforPopup', item);
    }, 
  }
}
</script>

<style lang="scss" scoped>
.infor-icon {
  position: absolute;
  top: 13px;
  left: 55px;
  cursor: pointer;
  width: 24px;
  height: 24px;
}
</style>