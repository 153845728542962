<template>
  <modal
    :width="550"
    :adaptive="true"
    class="inforPopup CEModal modal-scroll-bar"
    :name="'inforPopup_'+modal_key"
  >
    <div class="modal-header">
      <button class="button cus-close-modal_btn" @click="closeInforPopup()">
        <img src="/assets/img/close.svg" alt />
      </button>
    </div>
    <div class="modal-content text-center" v-html="inforData">
    </div>
  </modal>
</template>

<script>
export default {
  name: 'InforPopup',
  props: {
    inforData: [String, Object],
    modal_key: Number
  }, 
  methods: {
    closeInforPopup() {
      this.$modal.hide('inforPopup_' + this.modal_key);
    },
  }
};
</script>

<style lang="scss">
.inforPopup {
  .modal-content {
    padding: 50px;
  }
}
</style>