<template>
  <modal
    :width="480"
    :adaptive="true"
    class="CEModal modal-scroll-bar"
    name="ModalWecomNumbers"
  >
    <button class="button cus-close-modal_btn" @click="closeModal()">
      <img src="/assets/img/close.svg" alt />
    </button>
    <div class="font-weight-bold fs18 grey lighten-3 px-12 py-9">
			WeCom {{ $t("Choose a New Number") }}
		</div>
    <div class="d-flex justify-content-center">
      <div v-if="weComNumbers.result && weComNumbers.result.length" class="d-flex flex-wrap" style="max-width: 340px;">
        <v-btn 
          v-for="(item) in weComNumbers.result" 
          :key="item.id" 
          class="m-5 content-ltr" 
          :color="activedNumber==item.number ? 'primary' : ''"
          @click="handleChooseNumber(item.number)"
        >
          {{ item.number.substr(0,3) }} - {{ item.number.substr(3,item.number.length) }}
        </v-btn>
      </div>
      <div v-else>
        {{ $t("There is no new number") }}
      </div>
    </div>
    <div class="d-flex justify-content-end py-6 px-10 border-top">
      <v-btn
        class="text-none mx-10"
        @click="closeModal()"
      >
        {{ $t("Discard") }}
      </v-btn>
      <v-btn
        color="primary"
        class="text-none"
        @click="submit"
        :disabled="activedNumber == ''"
      >
        {{ $t("Save Changes") }}
      </v-btn>
    </div>
  </modal>	
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: "ModalWecomNumbers",
  data() {
    return {
      activedNumber: ''
    }
  },
  computed: {
    ...mapState({
      active_sims_data: (state) => state.supplier.active_sims_data,
      weComNumbers: state => state.payment.weComNumbers
    })
  },
  methods: {
    closeModal() {
      this.$modal.hide("ModalWecomNumbers");
      this.activedNumber = '';
    },
    openModal() {
      this.$modal.show("ModalWecomNumbers");
    },
    handleChooseNumber(num) {
      this.activedNumber = num;
    },
    async submit() {
      this.$emit('selectNewNumber', this.activedNumber);
      this.closeModal();
    }
  }
}
</script>
