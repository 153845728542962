<template>
  <modal
    :width="760"
    :adaptive="true"
    class="CEModal modal-scroll-bar"
    name="ModalWecomActivation"
  >
    <button class="button cus-close-modal_btn" @click="closeModal()">
      <img src="/assets/img/close.svg" alt />
    </button>
    <div class="font-weight-bold fs18 grey lighten-3 px-12 py-9">
			WeCom {{ $t("Number Activation") }}
		</div>
    <div class="border-bottom">
      <v-tabs
        v-model="tab"
        class="header-tabs"
      >
        <v-tab
          class="m-0 font-weight-bold text-none"
          small
          disabled
        >
          {{ $t("New Number") }}
        </v-tab>
        <v-tab
          class="m-0 font-weight-bold text-none"
          small
          disabled
        >
          {{ $t("Porting Number") }}
        </v-tab>
      </v-tabs>
    </div>
    <v-tabs-items class="px-10 py-8 fs14" v-model="tab">
      <v-tab-item style="min-height: 250px;">
        <v-row no-gutters class="mb-4">
          <v-col
            cols="12"
            sm="4"
          >
            {{ $t("Mobile Number") }}:
          </v-col>
          <v-col
            cols="12"
            sm="8"
            class="d-flex flex-wrap"
          >
            <div class="mb-4">
              <input type="text" class="number-input input" @keypress="isNumber($event)" v-model="MobileNumber" disabled>
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters  class="mb-8">
          <v-col
            cols="12"
            sm="4"
          >
            {{ $t("Sim Number") }}:
          </v-col>
          <v-col
            cols="12"
            sm="8"
          >
            <div class="d-flex mb-5 sim-input content-ltr">
              <div class="mx-2" style="height: 39px;">
                <input type="text" class="input" value="8997"  maxlength="4" size="4" disabled>
              </div>
              <div class="mx-2" style="height: 39px;">
                <input type="text" class="input" value="2096"  maxlength="4" size="4" disabled>
              </div>
              <div class="mx-2" style="height: 39px;">
                <input type="text" class="input" value="0000"  maxlength="4" size="4" disabled>
              </div>
              <div class="mx-2" style="height: 39px;">
                <input type="text" class="input" maxlength="4" size="4" placeholder="XXXX" @keypress="isNumber($event)" v-model="SIMNumber_sub1" @input="handleChangeSimSub1">
                <div class="color-red1 fs12 valide-error-msg" v-if="$v.SIMNumber_sub1.$error">
                  {{ $t('Invalid value') }}
                </div>
              </div>
              <div class="mx-2" style="height: 39px;">
                <input type="text" class="input" maxlength="4" size="4" placeholder="XXXX" @keypress="isNumber($event)" v-model="SIMNumber_sub2" ref="SIMNumber_sub2">
                <div class="color-red1 fs12 valide-error-msg" v-if="$v.SIMNumber_sub2.$error">
                  {{ $t('Invalid value') }}
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item style="min-height: 250px;">
        <v-row no-gutters class="mb-4">
          <v-col
            cols="12"
            sm="4"
          >
            {{ $t("Mobile Number") }}:
          </v-col>
          <v-col
            cols="12"
            sm="8"
            class="d-flex flex-wrap"
          >
            <div class="mb-4 mx-2">
              <input type="text" class="number-input input content-ltr" @keypress="isNumber($event)" v-model="MobileNumber" disabled>
              <div class="color-red1 fs12 valide-error-msg" v-if="$v.MobileNumber.$error">
                {{ $t('Invalid value') }}
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters  class="mb-8">
          <v-col
            cols="12"
            sm="4"
          >
            {{ $t("Sim Number") }}:
          </v-col>
          <v-col
            cols="12"
            sm="8"
          >
          <div class="d-flex mb-5 sim-input content-ltr">
              <div class="mx-2" style="height: 39px;">
                <input type="text" class="input" value="8997"  maxlength="4" size="4" disabled>
              </div>
              <div class="mx-2" style="height: 39px;">
                <input type="text" class="input" value="2096"  maxlength="4" size="4" disabled>
              </div>
              <div class="mx-2" style="height: 39px;">
                <input type="text" class="input" value="0000"  maxlength="4" size="4" disabled>
              </div>
              <div class="mx-2" style="height: 39px;">
                <input type="text" class="input" maxlength="4" size="4" placeholder="XXXX" @keypress="isNumber($event)" v-model="SIMNumber_sub1" @input="handleChangeSimSub1">
                <div class="color-red1 fs12 valide-error-msg" v-if="$v.SIMNumber_sub1.$error">
                  {{ $t('Invalid value') }}
                </div>
              </div>
              <div class="mx-2" style="height: 39px;">
                <input type="text" class="input" maxlength="4" size="4" placeholder="XXXX" @keypress="isNumber($event)" v-model="SIMNumber_sub2" ref="SIMNumber_sub2">
                <div class="color-red1 fs12 valide-error-msg" v-if="$v.SIMNumber_sub2.$error">
                  {{ $t('Invalid value') }}
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters class="mb-8">
          <v-col
            cols="12"
            sm="4"
          >
            {{ $t("Verification code") }}:
          </v-col>
          <v-col
            cols="12"
            sm="8"
          >
            <input type="text" class="number-input input content-ltr mx-2" @keypress="isNumber($event)" v-model="VerificationCode">
            <!-- <div class="color-red1 fs12 valide-error-msg" v-if="$v.VerificationCode.$error">
              {{ $t('Invalid value') }}
            </div> -->
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
    <div class="d-flex justify-content-end py-6 px-10 border-top">
      <v-btn
        class="text-none mx-10"
        @click="closeModal()"
      >
        {{ $t("Cancel") }}
      </v-btn>

      <span v-if="tab==1"></span>


      <v-btn
        color="primary"
        class="text-none"
        @click="submit"
        v-if="tab==1"
        :disabled="tab == 0 && !VerificationCode.length"
      >
        {{ $t("Activate") }}
      </v-btn>
      <v-btn
        color="primary"
        class="text-none"
        @click="submit"
        v-else
      >
        {{ $t("Save") }}
      </v-btn>
    </div>
  </modal>	
</template>

<script>
import { mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';

export default {
  name: "ModalWecomActivation",
  mixins: [validationMixin],
  data() {
    return {
      tab: null,
      prefix_sim_number: "899720960000",
      SIMNumber: '',
      SIMNumber_sub1: '',
      SIMNumber_sub2: '',
      MobileNumber: '',
      VerificationCode:'',
      supplierId: '',
      transactionId: '',
      user_type: '',
      productId: ''
    }
  },
  validations: {
    SIMNumber_sub1: {
      required,
      minLength: minLength(4),
      maxLength: maxLength(4)
    },
    SIMNumber_sub2: {
      required,
      minLength: minLength(4),
      maxLength: maxLength(4)
    },
    MobileNumber: {
      required
    },
    // VerificationCode: {
    //   required
    // },
  },
  methods: {
    ...mapActions('game_card_payment', {
      paymentVerificationRequest: 'paymentVerificationRequest'
    }),
    ...mapActions("alert", {
      errorAlert: "error"
    }),
    closeModal() {
      this.$modal.hide("ModalWecomActivation");
      this.SIMNumber_sub1 = "";
      this.SIMNumber_sub2 = "";
      this.MobileNumber = "";
      this.VerificationCode = "";
    },
    openModal(phone_number, supplierId, transaction_id, user_type, activedWeComTabID, productId) {
      this.MobileNumber = phone_number;
      this.supplierId = supplierId;
      this.tab = activedWeComTabID*1 - 1;
      this.transactionId = transaction_id;
      this.user_type = user_type;
      this.productId = productId;
      this.$modal.show("ModalWecomActivation");
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }

      let verification_code = true;
      if (this.tab == 1) {
        verification_code = this.VerificationCode
      }
      const transaction_id = this.transactionId;
      const phone_number = this.MobileNumber.replace(/-/g, "");
      const sim_number = this.prefix_sim_number + this.SIMNumber_sub1 + this.SIMNumber_sub2;
      if (phone_number) {
        this.paymentVerificationRequest({
          supplier_id: this.supplierId,
          payment_data: {
            transaction_id: transaction_id,
            verification_code: verification_code,
            phoneNumber: phone_number,
            userType: this.user_type,
            sim_number: sim_number,
            itemId: this.productId
          }
        })
          .then(
            result_data => {
              this.closeModal();
              if (result_data && result_data.hasOwnProperty('currentTransaction') && result_data.currentTransaction
                && result_data.hasOwnProperty('success') && result_data.success) {
                this.redirectToPaymentResult(result_data.currentTransaction);
              } else {
                this.errorAlert(this.$t(result_data.data.error || "Something went wrong, please contact support or try again later"));
              }
            },
            error => {
            }
          );
      }
      // this.closeModal();
    },
    redirectToPaymentResult(transaction_id) {
      if (this.user_type == "user") {
        this.$router.push({ name: 'CommunicationPaymentSuccess', params: { 'id': transaction_id } });
      } else {
        this.$router.push({ name: 'EndUserCommunicationPaymentSuccess', params: { 'id': transaction_id } });
      }
    },
    handleChangeSimSub1(e) {
      if (e.target.value.length == 4) {
        this.$refs.SIMNumber_sub2.focus();
      }
    }
  }
}
</script>

<style lang="scss">
.number-input {
  width: 140px;
  letter-spacing: 2px;
}
.number-select {
  width: 160px;
  letter-spacing: 2px;
  padding: 0;
}
.rtl-type {
  .sim-input {
    justify-content: flex-end;
  }
}
</style>